import { defaultTheme } from "styles/defaultTheme";
import { ODL_ICONS } from "components/FontIcon/FontIcon.constant";
import { enumFromValue } from "utils/enumFromValue";
import LodgementIcon from "assets/images/workflow-stage-lodgement-icon.svg";
import AssessmentIcon from "assets/images/workflow-stage-assessment-icon.svg";
import InspectionIcon from "assets/images/workflow-stage-inspection-icon.svg";
import CertificateIcon from "assets/images/workflow-stage-certificate-icon.svg";
import { serviceContainer } from "services/serviceContainer";
import get from "lodash/get";
import toString from "lodash/toString";
import toNumber from "lodash/toNumber";
import toArray from "lodash/toArray";

export enum WorkflowStageType {
  Lodgement = "Lodgement",
  Assessment = "Assessment",
  Inspections = "Inspections",
  Certificates = "Certificates",
}

export enum WorkflowStageStatus {
  Completed = "Completed",
  NotStarted = "NotStarted",
  InProgress = "Started",
  Suspended = "Suspended",
  AwaitingPayment = "AwaitingPayment",
  Rejected = "Rejected",
  Lapsed = "Lapsed",
  Withdrawn = "Withdrawn",
  Refused = "Refused",
}

export enum WorkflowStageItemsType {
  Steps = "Steps",
  Numbers = "Numbers",
}

export type IWorkflowStageItem = {
  date: string;
  status: WorkflowItemStatus;
  displayName: string;
  count: number;
};

export enum WorkflowItemStatus {
  NotStarted = "NotStarted",
  Completed = "Completed",
  Rejected = "Rejected",
  Lapsed = "Lapsed",
  Refused = "Refused",
  AwaitingPayment = "AwaitingPayment",
  Withdrawn = "Withdrawn",
  Suspended = "Suspended",
  Fail = "Fail",
  InProgress = "Started",
  Pass = "Pass",
  Remaining = "Remaining",
  ApplicationAccepted = "ApplicationAccepted",
  ApplicationRejected = "ApplicationRejected",
  ApplicationWithdrawn = "ApplicationWithdrawn",
  ConsentGranted = "ConsentGranted",
  ConsentIssued = "ConsentIssued",
  ConsentWithdrawn = "ConsentWithdrawn",
  ConsentRejected = "ConsentRejected",
  ConsentLapsed = "ConsentLapsed",
  CCCGranted = "CCCGranted",
  CCCIssued = "CCCIssued",
  CCCWithdrawn = "CCCWithdrawn",
  CCCRejected = "CCCRejected",
  CCCRefused = "CCCRefused",
  CCCLapsed = "CCCLapsed",
}

export type IWorkflowMeta = {
  label: string;
  icon: string;
  iconColour: string;
  colour: string;
  overdueColour?: string;
};

export enum WorkflowProgressStatus {
  Processing = "Processing",
  Suspended = "Suspended",
  Completed = "Completed",
  Rejected = "Rejected",
  Lapsed = "Lapsed",
  Withdrawn = "Withdrawn",
  Refused = "Refused",
}

export type IWorkflowStageProgress = {
  elapsedNum: number;
  suspendedNum: number;
  totalNum: number;
  startDate: string;
  endDate: string;
  status: WorkflowProgressStatus;
};

export type IWorkflowStage = {
  stageDisplayName: string;
  stageName: WorkflowStageType;
  stageStatus: WorkflowStageStatus;
  stageType: WorkflowStageItemsType;
  items: IWorkflowStageItem[];
  progress?: IWorkflowStageProgress;
};

export const ApplicationWorkflowUtil = {
  getStageLabel: (stage: IWorkflowStage) => {
    const { t } = serviceContainer.cradle.i18n;
    const workflowStageMetaData = ApplicationWorkflowUtil.getWorkflowStageMetaData(stage.stageStatus);
    const completeStatuses = [
      WorkflowItemStatus.Completed,
      WorkflowItemStatus.ApplicationAccepted,
      WorkflowItemStatus.ConsentGranted,
      WorkflowItemStatus.ConsentIssued,
      WorkflowItemStatus.CCCGranted,
      WorkflowItemStatus.CCCIssued,
    ];

    switch (stage.stageStatus) {
      case WorkflowStageStatus.NotStarted:
      case WorkflowStageStatus.Rejected:
      case WorkflowStageStatus.Lapsed:
      case WorkflowStageStatus.Withdrawn:
      case WorkflowStageStatus.Refused:
        return workflowStageMetaData.label;
    }

    if (stage.stageStatus === WorkflowStageStatus.Completed) {
      return ApplicationWorkflowUtil.getWorkflowStageCompleteLabel(stage.stageName);
    }

    let stepsCompleted = 0;
    let stepsTotal = 0;
    let stepsLabel = "Completed";

    if (stage.stageStatus === WorkflowStageStatus.Suspended) {
      return t("Suspended · RFIs");
    }
    if (stage.stageType === WorkflowStageItemsType.Steps) {
      stepsCompleted = stage.items.filter((step) => completeStatuses.includes(step.status)).length;
      stepsTotal = stage.items.length;
    } else {
      stepsLabel = "Passed";
      stage.items.forEach((item) => {
        if (item.count) {
          if (item.status === WorkflowItemStatus.Completed) {
            stepsCompleted = item.count;
          }
          stepsTotal = stepsTotal + item.count;
        }
      });
    }
    return t("({{stepsCompleted}}/{{stepsTotal}} {{stepsLabel}})", { stepsCompleted, stepsTotal, stepsLabel });
  },
  getWorkflowItemMetaData: (workflowItemStatus: WorkflowItemStatus): IWorkflowMeta => {
    const { t } = serviceContainer.cradle.i18n;
    switch (workflowItemStatus) {
      case WorkflowItemStatus.NotStarted:
        return {
          label: t("Not started"),
          icon: ODL_ICONS.WINDOW_MINIMIZE,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.light.allspice,
        };
      case WorkflowItemStatus.Remaining:
        return {
          label: t("Remaining"),
          icon: ODL_ICONS.WINDOW_MINIMIZE,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.light.allspice,
        };
      case WorkflowItemStatus.InProgress:
        return {
          label: t("Started"),
          icon: ODL_ICONS.TIMELAPSE,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.dark.fiordland,
          overdueColour: defaultTheme.palette.objective.yellow.main,
        };
      case WorkflowItemStatus.Completed:
        return {
          label: t("Completed"),
          icon: ODL_ICONS.CHECK,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.green.main,
        };
      case WorkflowItemStatus.Pass:
        return {
          label: t("Pass"),
          icon: ODL_ICONS.CHECK,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.green.main,
        };
      case WorkflowItemStatus.Rejected:
        return {
          label: t("Rejected"),
          icon: ODL_ICONS.ALERT,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.red.main,
        };
      case WorkflowItemStatus.Lapsed:
        return {
          label: t("Lapsed"),
          icon: ODL_ICONS.ALERT,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.red.main,
        };
      case WorkflowItemStatus.Refused:
        return {
          label: t("Refused"),
          icon: ODL_ICONS.ALERT,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.red.main,
        };
      case WorkflowItemStatus.AwaitingPayment:
        return {
          label: t("Awaiting payment"),
          icon: ODL_ICONS.CURRENCY_USD,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.red.main,
        };
      case WorkflowItemStatus.Withdrawn:
        return {
          label: t("Withdrawn"),
          icon: ODL_ICONS.ALERT,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.red.main,
        };
      case WorkflowItemStatus.Suspended:
        return {
          label: t("Suspended"),
          icon: ODL_ICONS.PAUSE,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.red.main,
        };
      case WorkflowItemStatus.Fail:
        return {
          label: t("Fail"),
          icon: ODL_ICONS.ALERT,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.red.main,
        };
      case WorkflowItemStatus.ApplicationAccepted:
        return {
          label: t("Application accepted"),
          icon: ODL_ICONS.CHECK,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.green.main,
        };
      case WorkflowItemStatus.ApplicationRejected:
        return {
          label: t("Application rejected"),
          icon: ODL_ICONS.ALERT,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.red.main,
        };
      case WorkflowItemStatus.ApplicationWithdrawn:
        return {
          label: t("Application withdrawn"),
          icon: ODL_ICONS.ALERT,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.red.main,
        };
      case WorkflowItemStatus.ConsentGranted:
        return {
          label: t("Consent granted"),
          icon: ODL_ICONS.CHECK,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.green.main,
        };
      case WorkflowItemStatus.ConsentIssued:
        return {
          label: t("Consent issued"),
          icon: ODL_ICONS.CHECK,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.green.main,
        };
      case WorkflowItemStatus.ConsentRejected:
        return {
          label: t("Consent rejected"),
          icon: ODL_ICONS.ALERT,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.red.main,
        };
      case WorkflowItemStatus.ConsentWithdrawn:
        return {
          label: t("Consent withdrawn"),
          icon: ODL_ICONS.ALERT,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.red.main,
        };
      case WorkflowItemStatus.ConsentLapsed:
        return {
          label: t("Consent lapsed"),
          icon: ODL_ICONS.ALERT,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.red.main,
        };
      case WorkflowItemStatus.CCCGranted:
        return {
          label: t("CCC granted"),
          icon: ODL_ICONS.CHECK,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.green.main,
        };
      case WorkflowItemStatus.CCCIssued:
        return {
          label: t("CCC issued"),
          icon: ODL_ICONS.CHECK,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.green.main,
        };
      case WorkflowItemStatus.CCCRejected:
        return {
          label: t("CCC rejected"),
          icon: ODL_ICONS.ALERT,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.red.main,
        };
      case WorkflowItemStatus.CCCRefused:
        return {
          label: t("CCC refused"),
          icon: ODL_ICONS.ALERT,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.red.main,
        };
      case WorkflowItemStatus.CCCWithdrawn:
        return {
          label: t("CCC withdrawn"),
          icon: ODL_ICONS.ALERT,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.red.main,
        };
      case WorkflowItemStatus.CCCLapsed:
        return {
          label: t("CCC lapsed"),
          icon: ODL_ICONS.ALERT,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.red.main,
        };
      default:
        return {
          label: t("Unknown"),
          icon: ODL_ICONS.WINDOW_MINIMIZE,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.light.allspice,
        };
    }
  },
  getWorkflowStageMetaData: (workflowStageStatus: WorkflowStageStatus): IWorkflowMeta => {
    const { t } = serviceContainer.cradle.i18n;
    switch (workflowStageStatus) {
      case WorkflowStageStatus.Completed:
        return {
          label: t("Completed"),
          icon: ODL_ICONS.CHECK,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.green.main,
        };
      case WorkflowStageStatus.NotStarted:
        return {
          label: t("Not started"),
          icon: ODL_ICONS.WINDOW_MINIMIZE,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.light.allspice,
        };
      case WorkflowStageStatus.InProgress:
        return {
          label: t("Started"),
          icon: ODL_ICONS.TIMELAPSE,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.dark.fiordland,
          overdueColour: defaultTheme.palette.objective.yellow.main,
        };
      case WorkflowStageStatus.Suspended:
        return {
          label: t("Suspended"),
          icon: ODL_ICONS.PAUSE,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.red.main,
        };
      case WorkflowStageStatus.AwaitingPayment:
        return {
          label: t("Awaiting payment"),
          icon: ODL_ICONS.CURRENCY_USD,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.red.main,
        };
      case WorkflowStageStatus.Rejected:
        return {
          label: t("Rejected"),
          icon: ODL_ICONS.ALERT,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.red.main,
        };
      case WorkflowStageStatus.Lapsed:
        return {
          label: t("Lapsed"),
          icon: ODL_ICONS.ALERT,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.red.main,
        };
      case WorkflowStageStatus.Withdrawn:
        return {
          label: t("Withdrawn"),
          icon: ODL_ICONS.ALERT,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.red.main,
        };
      case WorkflowStageStatus.Refused:
        return {
          label: t("Refused"),
          icon: ODL_ICONS.ALERT,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.red.main,
        };
      default:
        return {
          label: t("Unknown"),
          icon: ODL_ICONS.WINDOW_MINIMIZE,
          iconColour: defaultTheme.palette.objective.light.white,
          colour: defaultTheme.palette.objective.light.allspice,
        };
    }
  },
  getWorkflowStageCompleteLabel: (workflowStageName: string): string => {
    const { t } = serviceContainer.cradle.i18n;
    switch (workflowStageName) {
      case WorkflowStageType.Lodgement:
        return t("Accepted");
      case WorkflowStageType.Assessment:
        return t("Issued");
      case WorkflowStageType.Inspections:
        return t("Passed");
      case WorkflowStageType.Certificates:
        return t("Issued");
      default:
        return t("Completed");
    }
  },
};
// TODO: Data parsing logic below should be moved to Service, and enum parsing should be wrapped with Util

export const parseWorkflowStages = (workflowStagesJson?: string): IWorkflowStage[] | undefined => {
  if (!workflowStagesJson) {
    return;
  }
  return toArray(workflowStagesJson).map((stageJson) => parseWorkflowStage(stageJson));
};

export const parseWorkflowStage = (stageJson: string): IWorkflowStage => {
  return {
    stageDisplayName: toString(get(stageJson, "stageDisplayName")),
    stageName: parseWorkflowStageType(get(stageJson, "stageName")),
    stageStatus: parseWorkflowStageStatus(get(stageJson, "stageStatus")),
    stageType: parseWorkflowStageItemsType(get(stageJson, "stageType")),
    progress: parseWorkflowStageProgress(get(stageJson, "progress")),
    items: toArray(get(stageJson, "items")).map((itemJson) => parseWorkflowStageItem(itemJson)),
  };
};

// TODO: BLD-1987 typing issue with this function
export const parseWorkflowStageType: any = (value: string) =>
  enumFromValue(WorkflowStageType, value, WorkflowStageType.Lodgement);

// TODO: BLD-1987 typing issue with this function
export const parseWorkflowStageStatus: any = (value: string) =>
  enumFromValue(WorkflowStageStatus, value, WorkflowStageStatus.NotStarted);

// TODO: BLD-1987 typing issue with this function
export const parseWorkflowStageItemsType: any = (value: string) =>
  enumFromValue(WorkflowStageItemsType, value, WorkflowStageItemsType.Steps);

export const parseWorkflowStageProgress = (progressJson: any): IWorkflowStageProgress | undefined => {
  if (!progressJson) {
    return;
  }

  return {
    elapsedNum: toNumber(get(progressJson, "elapsedNum")),
    suspendedNum: toNumber(get(progressJson, "suspendedNum")),
    totalNum: toNumber(get(progressJson, "totalNum")),
    startDate: toString(get(progressJson, "startDate")),
    endDate: toString(get(progressJson, "endDate")),
    status: parseWorkflowProgressStatus(get(progressJson, "status")),
  };
};

export const parseWorkflowStageItem = (itemJson: any): IWorkflowStageItem => {
  const count = get(itemJson, "count");

  return {
    date: toString(get(itemJson, "date")),
    status: parseWorkflowItemStatus(get(itemJson, "status")),
    displayName: toString(get(itemJson, "displayName")),
    count: toNumber(count),
  };
};

export const parseWorkflowItemStatus: any = (value: string) =>
  enumFromValue(WorkflowItemStatus, value, WorkflowItemStatus.NotStarted);

export const parseWorkflowProgressStatus: any = (value: string) =>
  enumFromValue(WorkflowProgressStatus, value, WorkflowProgressStatus.Processing);

export const determineStageIcon = (stageName: WorkflowStageType) => {
  switch (stageName) {
    case WorkflowStageType.Lodgement:
      return LodgementIcon;
    case WorkflowStageType.Assessment:
      return AssessmentIcon;
    case WorkflowStageType.Inspections:
      return InspectionIcon;
    default:
      return CertificateIcon;
  }
};
